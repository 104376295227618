import React from 'react'
import Link from 'gatsby-link'
import { MDBAnimation, MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faUser } from '@fortawesome/pro-solid-svg-icons'

const BlogItem = props => {
    const node = props.data

    return (
        <MDBRow className="pb-5"> 
            <MDBCol md="12" className="pt-5">
                <MDBAnimation reveal type="fadeIn" duration="1587ms">
                    <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium"> <Link to={node.fields.slug} className="effect-noline-blog"> {node.frontmatter.title} </Link> </h3>
                    <p className="text-medium"> {node.frontmatter.description.length <= 160 ? node.frontmatter.description : node.frontmatter.description.substr(0, 160) + '...'} </p>

                    <MDBRow className="justify-content-left font-alt font-w-400 text-gray-light text-medium-small letter-spacing-1">                    
                        {node.frontmatter.author && ( 
                            <MDBCol lg="auto" md="4" xs="12"> <FontAwesomeIcon icon={faUser} className="mr-2" /> {node.frontmatter.author} 
                            </MDBCol> )}

                        {node.frontmatter.readtime && (
                            <MDBCol lg="auto" md="4" xs="12"> <FontAwesomeIcon icon={faClock} className="mr-2" /> {node.frontmatter.readtime} read 
                            </MDBCol> )}
                    </MDBRow>
                </MDBAnimation>
            </MDBCol>
        </MDBRow>
    )
}
export default BlogItem                